<template>
 <div class="pa-2">
  <v-form id=farm >
    <div class="form-content">
      <div class="register">
        <div class="cd-info" v-if="farm_code">{{farm_code}}</div>
        <v-select dense outlined 
          class="selectmid"
          id="company" 
          ref="companySelect" 
          label="경영체✽" 
          v-model="company"
          :items="companyItems"
          :menu-props="{ top: false, offsetY: true }"
          item-text="name"
          return-object
          @change="sameInfo=false"
        />

        <div class="flex mt-1">
          <v-text-field dense outlined 
            class="textfield"
            id="farm_name" 
            ref="farm_name" 
            :label="['CF02', 'CF04', 'CF05', 'CF06'].includes(bizstatus.code) ? '농장명✽' : '사업장명✽'"
            v-model="farm_name" 
          />
          <v-checkbox
            v-model="sameInfo"
            color="#3a4f3f"
            style="margin-top:-5px;"
            label="경영체와 동일"
            @change="checkCompany()"
          />
        </div>

        <div class="mt-5 flex">
          <v-select dense outlined 
            id="bizstatus" 
            ref="bizstatus" 
            class="selectmin"
            label="축종✽" 
            v-model="bizstatus"
            :items="bizstatusItems"
            :menu-props="{ top: false, offsetY: true }"
            item-text="name"
            return-object
          />
          <v-select dense outlined
          class="selectmin"
          v-model="country"
          ref="country" 
          label="표준시✽"
          :items="timeZone_list"
          item-text="name"
          item-value="timeZone"
          :menu-props="{ top: false, offsetY: true }"
          return-object
          @change="comboTimeZone()"
          />
          <v-text-field dense outlined
            style="width:150px;"
            class="textfield" 
            id="phone" 
            ref="phone" 
            label="전화번호" 
            placeholder="숫자만 입력"
            v-model="phone"
            @keyup="getPhoneMask(phone)"
          />
        </div>

        <div class="mt-2 flex">
          <v-text-field dense outlined 
            style="min-width:120px;"
            class="textfield"
            id="email_id" 
            ref="email_id" 
            label="이메일" 
            v-model="email_id" 
          />
          <div class="mr-1 mt-3">
            ＠ 
          </div>
          <v-combobox dense outlined 
            class="selectmid"
            id="email_host" 
            ref="email_host" 
            :items="['gmail.com','hotmail.com','naver.com','daum.net',]"
            :menu-props="{ top: false, offsetY: true }"
            placeholder="항목이 없으면 직접 입력"
            v-model="email_host" 
          />
        </div>

        <div class="flex mt-5">
          <v-text-field dense outlined
            disabled
            class="textfield"
            id="post_no" 
            ref="post_no" 
            label="우편번호✽" 
            v-model="post_no" 
            onKeyup="this.value=this.value.replace(/[^0-9]/g,'');"
            maxlength="5"
          />
          <v-btn text class="search_btn mt-1"
          @click="openPost()">
            검색
          </v-btn>
        </div>

        <div class="mt-2">
          <v-text-field dense outlined
            disabled
            style="max-width:450px;"
            class="textfield"
            id="address_1st" 
            ref="address_1st" 
            label="기본주소✽" 
            v-model="address_1st" 
            />
          </div>
          <div class="mt-2">
            <v-text-field dense outlined 
              style="max-width:450px;"
              class="textfield"
              id="address_last" 
              ref="address_last" 
              label="상세주소(빌딩명,동호수,상호)" 
              v-model="address_last" 
            />
        </div>

        <div class="flex mt-2">
          <v-text-field dense outlined
            disabled
            class="textfield"
            id="latitude" 
            ref="latitude"
            label="위도✽" 
            v-model="latitude" 
          />
          <v-text-field dense outlined
            disabled
            class="textfield"
            id="longitude" 
            ref="longitude"
            label="경도✽" 
            v-model="longitude" 
          />
        </div>

        <div class="mt-5 group-content">
          <h4 style="width:80px;">사용서비스</h4>
          <div class="flex vertical-center">
            <div class="flex  s-bt" style="margin-top:-25px;">
              <v-checkbox label="출하/사육" v-model="trace" color="#3a4f3f" :disabled="this.$getters.userInfo.grade > 'UT02'"></v-checkbox>
              <!-- <v-checkbox label="관리일지" v-model="farmData.dailyrecord" color="#005B96" :disabled="this.$getters.userInfo.grade > 'UT02'"></v-checkbox> -->
              <v-checkbox label="환기제어" v-model="fancontrol" color="#3a4f3f" :disabled="this.$getters.userInfo.grade > 'UT02'"></v-checkbox>
              <v-checkbox label="악취제어" v-model="deodorizer" color="#3a4f3f" :disabled="this.$getters.userInfo.grade > 'UT02'"></v-checkbox>
              <v-checkbox label="ESG" v-model="esg" color="#3a4f3f" :disabled="this.$getters.userInfo.grade > 'UT02'"></v-checkbox>
            </div>
          </div>
        </div>

        <div class="mt-3">
          <v-flex xs12 lg6>
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field dense outlined clearable style="height: 50px;"
                  id="deldate" 
                  ref="deldate" 
                  v-model="deldate"
                  label="삭제일"
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                no-title locale="ko-KR"
                v-model="deldate"
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-flex>
        </div>

        <div>
          <v-textarea outlined dense rows="2" class="memo-height"
            style="max-width:450px;"
            label="비고"
            id="history" 
            ref="history" 
            v-model="history"
            value=""
            persistent-hint :hint="hint"
          ></v-textarea>
        </div>

        <div>
          <details class="setting_condition" >
            <summary style="font-weight:bold;"> 
              <div> 상세등록 </div>
              <span class="mdi mdi-24px mdi-package-down">
              </span>
            </summary>

            <div class="mt-3 flex">
                <v-select dense outlined v-if="bizstatus.code == 'CF02'"
                  v-model="biz_structure" 
                  ref="biz_structure" 
                  class="selectmid"
                  label="경영형태"
                  :items="['일관경영','번식경영1 (번식‣분만)','번식경영2 (번식‣분만‣새끼)','비육경영1 (새끼‣비육)','비육경영2 (비육)']"
                  item-text="name"
                  :menu-props="{ top: false, offsetY: true }"
                  return-object
                />
              
              <v-text-field dense outlined 
                class="textfield"
                id="business_no" 
                ref="business_no"
                label="사업자번호/법인번호" 
                placeholder="사업자 또는 법인 등록번호의 숫자만 입력"
                v-model="business_no" 
                @keyup="business_no ? getRegMask(business_no) : null"
              />
            </div>

            <div class="mt-2 flex">
              <v-text-field dense outlined 
                class="textfield"
                id="register_no" 
                ref="register_no" 
                label="축산허가번호"
                v-model="register_no" 
              />
              <v-text-field dense outlined 
                class="textfield"
                id="etc3" 
                ref="etc3" 
                label="축산업고유번호(5자)"
                placeholder="숫자+영문조합"
                v-model="etc3" 
              />
            </div>

            <div class="mt-2 flex">
              <v-text-field dense outlined
                v-show="esg"
                class="textfield"
                id="kepco_no" 
                ref="kepco_no" 
                label="한전고객번호"
                placeholder="전력사용량 사용 시 입력"
                v-model="kepco_no" 
              />
              
              <v-text-field dense outlined
                v-show="esg || trace"
                class="textfield" 
                id="farmUnique_no" 
                ref="farmUnique_no"
                label="농장식별번호"
                placeholder="ESG사용 시 입력 (숫자 6자리)"
                v-model="farmUnique_no" 
              />
            </div>

            <div class="flex" v-if="(bizstatus.code == 'CF05' || bizstatus.code =='CF06')">
              <div class="cow_info"></div>
              <div class="cow_notice"> 농장식별번호-농장주생년월일로 입력해주세요</div>
            </div>

            <div class="mt-5 group-content top-group" v-if="bizstatus.code == 'CF02'">
              <div class="flex radio-content">
                <div class="flex">
                  <v-radio-group dense outlined row 
                    id="owner_yn" 
                    ref="owner_yn" 
                    v-model="owner_yn">
                      <template v-slot:label>
                        <div class="set_name"> 소유자 <br/>근무여부 </div>
                      </template>
                      <v-radio label="Y" value="Y" color="#005B96"/>
                      <v-radio label="N" value="N" color="#88191a"/>    
                  </v-radio-group>
                </div>

                <div class="flex">
                  <v-radio-group dense outlined row 
                    id="haccp_yn" 
                    ref="haccp_yn" 
                    v-model="haccp_yn"
                  >
                    <template v-slot:label>
                      <div class="set_name"> HACCP<br/> 인증 </div>
                    </template>
                    <v-radio label="Y" value="Y" color="#005B96"/>
                    <v-radio label="N" value="N" color="#88191a"/> 
                  </v-radio-group>
                </div>
              </div>
            
              <div class="flex radio-content laptop-radio">
                <div class=flex>
                  <v-radio-group dense outlined row
                    id="disinfection_yn" 
                    ref="disinfection_yn" 
                    v-model="disinfection_yn"
                  >
                    <template v-slot:label>
                      <div class="set_name"> 소독 여부 </div>
                    </template>
                    <v-radio label="Y" value="Y" color="#005B96"/>
                    <v-radio label="N" value="N" color="#88191a"/> 
                  </v-radio-group>
                </div>

                  <div class="flex">
                    <v-radio-group dense outlined row
                      id="fumigator_yn" 
                      ref="fumigator_yn" 
                      v-model="fumigator_yn"
                    >
                      <template v-slot:label>
                        <div class="set_name"> 방역 여부 </div>
                      </template>
                      <v-radio label="Y" value="Y" color="#005B96"/>
                      <v-radio label="N" value="N" color="#88191a"/> 
                    </v-radio-group>
                  </div>
                </div>
              </div>
          </details>
        </div>
        
        <div class="flex vertical-center mt-5">
          <v-btn class="rounded-pill normal-btn mr-2 mb-5" 
              :loading= "loadingSave"
              id="save" 
              ref="save" 
              type="submit" 
              @click.prevent="save()"
              v-show= "$getters.userInfo.grade == 'UT01'"
              >
              저장
            </v-btn>
            <v-btn class="rounded-pill new-btn mb-5 mr-2" 
              id="newRegister" 
              ref="newRegister"
              @click="newRegister()">
              신규
            </v-btn>
        </div>
      </div>
    
      <div class="list">
        <v-select dense outlined class="select" 
          id="companySelect" 
          ref="companySelect"
          label="경영체✽" 
          v-model="companySelect"
          :items="companyItems"
          :menu-props="{ top: false, offsetY: true }"
          no-data-text="등록된 자료가 없거나 접근 권한이 없습니다."
          item-text="name"
          return-object
          @change="listFarms()"
          clearable
        /> 

        <v-data-table dense height="530" 
              fixed-header
              hide-default-footer
              :items-per-page= "500"
              :loading="loading"
              :headers="headers"
              :items="items"
              no-data-text="등록된 자료가 없거나 접근 권한이 없습니다."
              class="elevation-3 mt-3"
              id="items" 
              ref="items" 
              @click:row="infoDetail"
              sort-by="farm_name"
              item-key="farm_cd"
              single-select
            >

            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color=#3a4f3f
                    medium
                    @click="childBuildings(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-share-variant
                  </v-icon>
                </template>
                <span class="tooltip">하위돈방/위치</span>
              </v-tooltip>
            </template>
          </v-data-table> 

          <div class="flex vertical-center">
            <v-btn rounded-pill class="elevation-3 ma-3 rounded-pill normal-btn" style="width: 155px !important;"
                id="listFarms" 
                ref="listFarms" 
                @click="listFarms()">
                <img src="@/assets/icon_refresh.svg" alt="새로고침" />
                <span class="ml-1"> 목록고침 </span>
              </v-btn>
          </div>
      </div>
    </div>
  </v-form>
  </div>
</template>

<script>
import Apis from '@/api/apis'
import Common from "@/utils/custom/common.js";
import dateUtil from "@/utils/custom/dateProperty.js";
import timeZone_list from "@/assets/json/timezone.json";

export default {
  name: 'Farm',
  
  data: () => ({
    headers: [
      { text: '',align: 'center', sortable: false, value: 'actions', width: "50px", groupable: false },
      { text: '사업장명', value: 'farm_name', width: "150px" },
      { text: '코드', value: 'farm_cd', width: "50px" },
      { text: '경영체', align: 'left', sortable: true, value: 'company_name', width: "160px" },
      { text: '업태', align: 'left', sortable: true, value: 'biz_status', width: "90px"},
      { text: '전화번호', value: 'phone', width: "150px"},
      { text: '등록일', value: 'regdate', width: "120px" },
      { text: '사용중지일', value: 'deldate', width: "120px"},
      { text: '비고', value: 'history', width: "200px"},
    ],

    timeZone_list:timeZone_list,
    country:'',

    items : [],
    companySelect : {},
    company : {},
    companyItems : [],

    selectedFarm: {},
    routedFarm: "",

    farm_code : "",
    farm_name : "",

    bizstatus : {},
    bizstatusItems : Common.comboBaseCode("CF"),

    sameInfo:false,
 
    business_no: "",
    biz_structure:"",

    register_YN: "",
    register_no: "",
    kepco_no:"",
    farmUnique_no:"",
    post_no: "",
    address_1st: "",
    address_last: "",
    latitude: "",
    longitude: "",
    email_id: "",
    email_host: "",
    phone: "",
    owner_yn: "",
    haccp_yn: "",
    disinfection_yn: "",
    fumigator_yn: "",
    deldate: "",
    history: "",
    hint : "",
    etc3:"",
    
    trace:false,
    // dailyrecord: false,
    fancontrol: false,
    deodorizer: false,
    esg: false,

    // businessDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    menu: false,
    modal: false,
    menu1: false,
    menu2: false,

    loading: false,
    loadingSave: false,

    label_name: "사업장명✽",
  }),
  created() {
    this.comboCompanies();
    // console.log("param",this.$route.params);

    if (this.$route.params.code) {
        // console.log("param1",this.$route.params)
        this.company = this.$route.params;
      }

    else if (!this.isNull(this.$route.params)) {
      if (this.$route.params.company_cd) {
        // console.log("param2",this.$route.params)
        this.company = this.$route.params;
      } else {
        this.routedFarm = this.$route.params.farm_cd;
      }
    }
    this.listFarms();
    // sessionStorage.setItem("infoTab",1)
  },

  methods: {

    comboTimeZone(){
      if(this.timeZone_list && this.timeZone_list.timeZone){
        this.country = {name : this.country.name, timezone:this.country.timeZone}
      }
      // console.log(this.country)
    },
    check (e) {
      if(e) {
        if (!this.company.code) {
          alert("경영체를 선택해주세요");
          this.$store.commit("resMessage","경영체를 먼저 선택해주세요");
          // this.clearData();
          return;
        } 
        Apis.infoCompany({
          company_cd: this.company.code, 
          },(res) => {  
            if (res.result) {
              this.farm_name = res.data.company_name;
              this.post_no = res.data.post_no;
              this.address_1st = res.data.address_1st;
              this.address_last = res.data.address_last;
              let email = res.data.email1.split("@",2);
              this.email_id = email[0];
              this.email_host = email[1];
              this.phone = Common.getMask(res.data.phone1);
            } else {
              alert(res.message);
            }
          }).catch( (err) => {  // API 오류 처리
              console.log("API 호출 오류")
              alert(err);
              // console.log(err)
          }
        ) 
      } else {
        this.farm_name = "";
        this.post_no = "";
        this.address_1st = "";
        this.address_last = "";
        this.email_id = "";
        this.email_host = "";
        this.phone = "";
      }
    },
    clearData() {
      this.company = {},
      this.farm_code = "",
      this.farm_name = "",

      this.sameInfo = false,

      this.country={},
      
      this.bizstatus = {},
      this.business_no = "",
      this.biz_structure = "",

      this.register_YN = "",
      this.register_no = "",
      this.kepco_no = "",
      this.farmUnique_no="",
      this.post_no = "",
      this.address_1st = "",
      this.address_last = "",
      this.latitude = "",
      this.longitude = "",
      this.email_id = "",
      this.email_host = "",
      this.phone = "",
      this.owner_yn = "",
      this.haccp_yn = "",
      this.disinfection_yn = "",
      this.fumigator_yn = "",
      this.deldate = "",
      this.history = ""
      this.hint = "";
      this.trace = false;
      this.fancontrol = false;
      this.deodorizer = false;
      this.esg = false;
      this.etc3 = false;
    },
    checkData() {

      if (!this.company.code) {
        this.$refs.company.focus();
        return "상위 경영체를 선택입력하세요";
      }
      if (!this.bizstatus.code) {
        this.$refs.bizstatus.focus();
        return "축종을 선택하세요.";
      }
      if (!this.farm_name.trim()) {
        this.$refs.farm_name.focus();
        return "사업장(농장/사업장)명을 입력하세요";
      }
      // if (this.business_no.trim()) {
      //   if (!(Common.checkBizNo(this.business_no)) && !(Common.checkRegNo(this.business_no))) {
      //     this.$refs.business_no.focus();
      //     return "사업자/법인 등록번호가 유효하지 안습니다.";
      //   }  
      // }  
      // if (this.bizstatus && this.bizstatus.code === 'CF02') {
      //   if (!this.register_YN) {
      //     this.$refs.register_y.$el.children[1].focus()
      //     return "농장/사업장 등록여부를 선택하세요";
      //   }
      // }
      if (!this.country) {
        this.$refs.country.focus();
        return "해당 농장의 국가를 선택하세요";
      }
      if (!this.address_1st.trim()) {
        this.$refs.address_1st.focus();
        return "기본주소를 입력하세요";
      }
      if (!Common.isNumeric(this.latitude)) {
        this.$refs.latitude.focus();
        return "위도 좌표를 구글지도등에서 확인후 입력하세요";
      }
      if (Number(this.latitude) > 38.6144 || Number(this.latitude) < 33.167884)   {
        this.$refs.latitude.focus();
        return "위도 범위가 아닙니다";
      }
      if (!Common.isNumeric(this.longitude)) {
        this.$refs.longitude.focus();
        return "경도 좌표를 구글지도등에서 확인후 입력하세요";
      }
      if (Number(this.longitude) > 130.919948 || Number(this.longitude) < 125.36977)   {
        this.$refs.longitude.focus();
        return "경도 범위가 아닙니다";
      }
      // if (!this.phone.trim()) {
      //   this.$refs.phone.focus();
      //   return "전화번호를 입력하세요";
      // }
      return "";
    },
    save() {
      // console.log("checkdata", this.checkData());
      this.$store.commit("resMessage","");
      let msgCheckdata = this.checkData(); 
      if (msgCheckdata){
        this.$store.commit("resMessage",msgCheckdata);
        // alert(msgCheckdata);
        return;
      }
      this.loadingSave = true;
      if (this.farm_code) {
        // console.log("업데이트");
        Apis.updateFarmFree({
          farm_cd: this.farm_code,
          company_cd: this.company.code,
          farm_name: this.farm_name,
          business_no: this.business_no.replace(/[^0-9]/g, ''),
          biz_status: this.bizstatus.code,
          reg_yn: this.register_YN,
          reg_no: this.register_no.trim(),
          kepco_no: this.kepco_no.trim(),
          post_no: this.post_no,
          address_1st: this.address_1st.trim(),
          address_last: this.address_last.trim(),
          latitude: Number(this.latitude),
          longitude: Number(this.longitude),
          email1: this.email_id.trim() + "@" + this.email_host, 
          email2: this.biz_structure,
          phone1: this.phone.replace(/[^0-9]/g, ''),
          phone2: "",
          owner_yn: this.owner_yn,
          haccp_yn: this.haccp_yn,
          disinfection_yn: this.disinfection_yn,
          fumigator_yn: this.fumigator_yn,
          deldate: this.deldate,
          history: this.history.trim(),
          etc1: this.country.timeZone,
          etc2: this.farmUnique_no.replaceAll(' ', ''),
          etc3: this.etc3,
          trace:this.trace,
          // dailyrecord: this.dailyrecord,
          fancontrol: this.fancontrol,
          deodorizer: this.deodorizer,
          reserve1: this.esg,
          },(res) => {  
            if (res.result) {
              this.loadingSave = false;
              this.$store.commit("resMessage",res.message);
              // this.$router.push({
              //           name: "ListFarms",
              //           params: {farm_cd: this.farm_code}
              // });
              this.clearData();
              this.listFarms();
            } else {
              this.loadingSave = false;
              alert(res.message);
            }
          }).catch( (err) => {  // API 오류 처리
              this.loadingSave = false;
              console.log("API 호출 오류",err)
              alert(err);
          }
        )
      } else {
        // console.log("인서트");
        Apis.insertFarm({
          company_cd: this.company.code,
          farm_name: this.farm_name,
          
          business_no: this.business_no.replace(/[^0-9]/g, ''),
          biz_status: this.bizstatus.code,

          reg_yn: this.register_YN,
          reg_no: this.register_no.trim(),
          kepco_no: this.kepco_no.trim(),
          post_no: this.post_no,
          address_1st: this.address_1st.trim(),
          address_last: this.address_last.trim(),
          latitude: Number(this.latitude),
          longitude: Number(this.longitude),
          email1: this.email_id.trim() + "@" + this.email_host, 
          email2: this.biz_structure,
          phone1: this.phone.replace(/[^0-9]/g, ''),
          phone2: "",
          owner_yn: this.owner_yn,
          haccp_yn: this.haccp_yn,
          disinfection_yn: this.disinfection_yn,
          fumigator_yn: this.fumigator_yn,
          deldate: this.deldate,
          history: this.history.trim(),
          etc1: this.country.timeZone,
          etc2: this.farmUnique_no.replaceAll(' ', ''),
          etc3: this.etc3,
          trace:this.trace,
          // dailyrecord: this.dailyrecord,
          fancontrol: this.fancontrol,
          deodorizer: this.deodorizer,
          reserve1: this.esg,
          },(res) => {  
            if (res.result) {
              this.loadingSave = false;
              this.$store.commit("resMessage",res.message);
              this.clearData();
              this.listFarms();
            } else {
              this.loadingSave = false;
              alert(res.message);
            }
          }).catch( (err) => {  // API 오류 처리
              this.loadingSave = false;
              console.log("API 호출 오류",err)
              alert(err);
          }
        )
      }
    },

    listFarms() {
      this.$store.commit("resMessage","");
      this.loading = true;
      this.companySelect =  this.companySelect || {};

      Apis.listFarms({
        company_cd: this.companySelect.code || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd")
      } ,(res) => {  // 정상처리
        // console.log("res",res);
        res.data.forEach(element => {
          element.phone = Common.getMask(element.phone);
        });
        this.items = res.data;
        this.$store.commit("resMessage",res.message);
        this.loading = false;
      }).catch( (err) => {  // 개별 API 오류 처리 예제
        console.log("listFarms 호출 오류",err);
        this.$router.push("/");
        this.loading = false;
      }) 
    }, 
    childBuildings(value) {
      // alert("경옃체에 속한 농장/사업장목록으로")
      this.loadingTable = true;
      this.$router.push({
                        // path: `/farminfo/registerinfo/building2`,
                        name:'building',
                        params:
                          { company: {code: value.company_cd, name: value.company_name},
                            farm: {company_cd: value.company_cd, company_name: value.company_name, code: value.farm_cd, name: value.farm_name},}
                       });
      this.loadingTable = false;
    },
    infoDetail(value, row) {
      row.select(true)

      this.$store.commit("resMessage","");
      this.loading = true;
      this.clearData();

      Apis.infoFarm({
        // session_id: this.$getters.sessionId,
        farm_cd: value.farm_cd, 
        },(res) => {  
          if (res.result) {
            this.farm_code = res.data.farm_cd;
            // console.log("data=", res.data.company_cd,res.data.company_name);
            this.company = {code: res.data.company_cd.substr(0,11), name: res.data.company_cd.substr(11)};
            this.farm_name = res.data.farm_name;
            this.country = res.data.etc1;
            this.bizstatus = {code: res.data.biz_status.code, name: res.data.biz_status.name};
            this.business_no = Common.getRegMask(res.data.business_no);
            this.country = res.data.etc1;
            this.register_YN = res.data.reg_yn;
            this.register_no = res.data.reg_no;
            this.kepco_no = res.data.kepco_no;
            this.farmUnique_no = res.data.etc2;
            this.etc3 = res.data.etc3;
            this.post_no = res.data.post_no; 
            this.address_1st = res.data.address_1st;
            this.address_last = res.data.address_last;
            this.latitude = res.data.latitude;
            this.longitude = res.data.longitude;
            let email = res.data.email1.split("@",2);
            this.email_id = email[0];
            this.email_host = email[1];
            this.biz_structure = res.data.email2,
            this.phone = Common.getMask(res.data.phone1);
            this.owner_yn = res.data.owner_yn;
            this.haccp_yn = res.data.haccp_yn;
            this.disinfection_yn = res.data.disinfection_yn;
            this.fumigator_yn = res.data.fumigator_yn;
            this.deldate = res.data.deldate;
            this.trace = res.data.trace;
            // this.dailyrecord = res.data.dailyrecord;
            this.fancontrol = res.data.fancontrol;
            this.deodorizer = res.data.deodorizer;
            this.esg = res.data.reserve1;
            this.history = res.data.history;
            this.hint = res.data.regdate + "에 등록, " + res.data.chguser + "님이 " + res.data.chgdate + "에 최종 수정";
            this.loading = false;

            this.$store.commit("resMessage",res.message);
          } else {
            this.loading = false;
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            this.loading = false;
            console.log("API 호출 오류",err)
            alert(err);
        }
      ) 
    },
    loadDaumPostCode() {
      return new Promise((resolve, reject) => {
        if (window.daum && window.daum.Postcode) {
          resolve();
        } else {
          const daumScript = document.createElement('script');
          daumScript.src = '//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';
          daumScript.async = true;

          daumScript.onload = () => resolve();
          daumScript.onerror = () => reject(new Error('Failed to load Daum Postcode API.'));
          document.head.appendChild(daumScript);
        }
      });
    },
    loadKakaoMapAPI() {
      return new Promise((resolve, reject) => {
        if (window.kakao && kakao.maps && kakao.maps.services) {
          kakao.maps.load(() => resolve());
        } else {
          const kakaoScript = document.createElement('script');
          kakaoScript.src = 'https://dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=84904cd1cc1af1bd9ff7067996ed7dd2&libraries=services';
          kakaoScript.async = true;

          kakaoScript.onload = () => {
            kakao.maps.load(() => resolve());
          };
          kakaoScript.onerror = () => reject(new Error('Failed to load Kakao Maps API.'));
          document.head.appendChild(kakaoScript);
        }
      });
    },
    openPost() {
      this.loadDaumPostCode()
        .then(() => {
          // console.log('Daum Postcode API loaded.');
          new daum.Postcode({
            oncomplete: (data) => {
              // 1. 주소 결과를 farmData에 저장
              this.post_no = data.zonecode; // 우편번호
              this.address_1st = data.roadAddress; // 기본주소
              // console.log('Address:', data.roadAddress);

              // 2. Kakao Maps Geocoder로 주소 검색
              this.loadKakaoMapAPI()
                .then(() => {
                  // console.log('Kakao Maps API loaded.');
                  kakao.maps.load(() => {
                    const geocoder = new kakao.maps.services.Geocoder();
                    geocoder.addressSearch(data.roadAddress, (result, status) => {
                      if (status === kakao.maps.services.Status.OK) {
                        // 3. 위도, 경도를 farmData에 저장
                        this.latitude = result[0].y;
                        this.longitude = result[0].x;
                        // console.log('Latitude:', result[0].y, 'Longitude:', result[0].x);
                      } else {
                        console.error('Geocoding failed:', status);
                      }
                    });
                  });
                })
                .catch((error) => {
                  console.error('Kakao Maps API 로드 실패:', error.message);
                });
            },
          }).open();
        })
        .catch((error) => {
          console.error('Daum Postcode API 로드 실패:', error.message);
        });
    },
    checkCompany(){
      if (this.sameInfo) {
        this.farm_name = this.company.name || '';
      } else {
        this.farm_name = '';
      }
    },
    comboCompanies() {
      Apis.comboCompanies({
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.companyItems = [];

            // console.log(res.data);
            for (let i in res.data) {
            this.companyItems.push({
              code: res.data[i].company_cd,
              name: res.data[i].company_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("API 호출 오류",err)
            alert(err);
        }
      ) 
    },

    newRegister() {
      this.$store.commit("resMessage","");
      this.clearData();
      this.$refs.company.focus();
      this.$store.commit("resMessage","새로 등록할 준비가 되었습니다.");
    },

    getPhoneMask(val) {
      this.phone = Common.getMask(val)
    },
    getRegMask(val) {
      // let res = Common.getRegMask(val)
      this.business_no = Common.getRegMask(val)
    },

  }  // -----------methods 

}
</script>

<style lang="scss" scoped>
@import './style/info_style.scss';


@media screen and (max-width: 820px){
  .flex_mobile{
    flex-wrap: wrap;
  }
  .dataTable{
    width:100% !important;
  }
}
.dataTable{
  width:60%;
}
.v-data-table::v-deep{
  thead.v-data-table-header tr {
    th {
      background-color: #436251 !important;
      color: white !important;
      font-size:13px;
      height:40px !important;
    }
  }
}
.module_container{
  height:90px;
  margin-bottom:10px;
  padding:10px;
  border-radius:8px;
  border: 1.5px solid #a3a3a3;
}
.module_set{
  margin-top:-10px;
  display:flex;
}
.normal-btn{
  background-color: #3a4f3f !important;
  color:white;
  width:100px;
  height:45px !important;
  font-weight:bold;
  font-size:15px;
  letter-spacing: -0.5px;
  border-color:transparent;
}
.new-btn{
  color:#3a4f3f !important;
  width:100px;
  height:45px !important;
  font-weight:bold;
  font-size:15px;
  letter-spacing: -0.5px;
  border-color:transparent;
  background-color: rgb(248, 248, 248) !important;
}
.dialog-btn{
  color:white !important;
  font-size:12pt;
  font-weight: bold !important;
  background-color: #88191a !important;
}
.display{
  display:flex;
  flex-direction: row;
}
.group-content{
  border: 1px solid #E9E9E9;
  padding:10px 5px 5px 10px;
  border-radius: 10px;
  // height:auto;
}
.cow_info{
  width:190px;
  margin-right:5px;
}
.cow_notice{
  font-size:11px;
  letter-spacing: -0.75px;
  color:rgb(190, 0, 0);
}
.radio-content{
  // margin-left:-10px;
  // height:45px;
  margin-top:-15px;
  margin-right:5px;
}
.set_name{
  width:50px;
  font-size:13px;
  letter-spacing: -1.25px;
}
@media screen and (max-width: 1440px) {
  /* 1440px 이하 해상도 (고해상도 노트북) */
  .top-group{
    padding:5px 10px 20px 10px;
  }
  .radio-content{
    flex-direction: column;
    // width:100%;
    // height: auto; /* 높이를 자동으로 조절 */
  }
  .radio-content div{
    height:35px !important;
  }
  .laptop-radio{
    margin-top:5px !important;
  }
}
@media screen and (max-width: 768px){
.display{
  flex-direction: column;
}
::v-deep .v-input--checkbox .v-label{
  font-size: 13px;
  letter-spacing: -1px;
}
.radio-content{
  flex-direction: column;
}
}



</style>
